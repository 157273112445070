import classNames from "classnames";
import { Entry } from "contentful";

import { useBreakpoint } from "~/contexts/breakpoint";
import Editorials from "~/types/editorials";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";

import styles from "./breakpoint-aware-asset.module.scss";
import MediaAsset from "./media-asset";

type Props = {
  entry: Entry<unknown>;
  className?: string;
  fill?: boolean;
  width?: number;
  height?: number;
  videoConfig?: {
    muted?: boolean;
    loop?: boolean;
    autoPlay?: boolean;
    controls?: boolean;
    playsInline?: boolean;
  };
  noLazy?: boolean;
};

export default function BreakpointAwareAsset(props: Props) {
  const breakpoint = useBreakpoint();

  if (!props.entry || !isActiveEntry(props.entry) || !breakpoint) return null;

  assertEditorialType<Editorials.ContentAwareAsset>(props.entry, "breakpointAwareAsset");

  return (
    <>
      <MediaAsset
        {...props}
        className={classNames(styles.mobileAsset, props.className)}
        entry={props.entry.fields.mobileAsset}
        sizes="(min-width: 900px) 0vw, 100vw"
      />
      <MediaAsset
        {...props}
        className={classNames(styles.desktopAsset, props.className)}
        entry={props.entry.fields.desktopAsset}
        sizes="(min-width: 900px) 100vw, 0vw"
      />
    </>
  );
}
