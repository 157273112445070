import classNames from "classnames";
import { Entry } from "contentful";
import Link from "next/link";
import { useRouter } from "next/router";
import { CSSProperties } from "react";

import BreakpointAwareAsset from "~/components/common/breakpoint-aware-asset";
import Container from "~/components/common/container";
import EscapeNewLine from "~/components/common/escape-new-line";
import Grid from "~/components/common/grid";
import HighlightedText from "~/components/common/highlighted-text";
import MediaAsset from "~/components/common/media-asset";
import { useServerSideProps } from "~/contexts/server-side-props";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";
import textUtils from "~/utils/text-utils";

import buttonStyles from "../../common/button.module.scss";
import Countdown from "../../common/countdown";
import styles from "./hero-slide.module.scss";

type Props = {
  entry: Entry<unknown>;
  isHeroSlider?: boolean;
  noLazy?: boolean;
};

export default function HeroSlide(props: Props) {
  const router = useRouter();
  const { serverSideProps } = useServerSideProps();

  if (!isActiveEntry(props.entry)) return null;

  assertEditorialType<Editorials.HeroSlide>(props.entry, "heroSlide");

  const inspectorMode = contentfulUtils.useInspectorMode(props.entry);

  const panelVariant = props.entry.fields.panelVariant;

  const aspectRatioMobile = props.entry.fields.media.fields?.aspectRatioMobile ?? "unset";
  const aspectRatioDesktop = props.entry.fields.media.fields?.aspectRatioDesktop ?? "unset";

  const styleAspectRatio = {
    "--hero-slide-aspect-ratio-mobile": aspectRatioMobile,
    "--hero-slide-aspect-ratio-desktop": aspectRatioDesktop,
  } as CSSProperties;

  const panelClassName = classNames(
    styles.panel,
    aspectRatioMobile !== "unset" && !props.isHeroSlider ? styles.panelMarginBottomMobile : undefined,
    aspectRatioDesktop !== "unset" && !props.isHeroSlider ? styles.panelMarginBottomDesktop : undefined,
    props.entry.fields.centerText ? styles.centerText : undefined,
    props.entry.fields.centerPanel ? styles.centerPanel : undefined,
    panelVariant == "black" ? styles.panelVariant : undefined
  );

  // static panel content
  const panelContent = (
    <>
      <h2 {...inspectorMode?.getProps("title")} className={styles.title}>
        <HighlightedText text={props.entry.fields.title} />
      </h2>
      <div className={styles.descriptionWrapper}>
        {props.entry.fields.description && (
          <p {...inspectorMode?.getProps("description")} className={styles.description}>
            <EscapeNewLine text={props.entry.fields.description} />
          </p>
        )}
        {props.entry.fields.logo && (
          <div className={styles.mediaWrapper}>
            <MediaAsset entry={props.entry.fields.logo} />
          </div>
        )}
      </div>
      {props.entry.fields.link && isActiveEntry(props.entry.fields.link) && (
        <div
          className={classNames(
            buttonStyles.button,
            buttonStyles.linkButton,
            panelVariant == "black" ? buttonStyles["secondary"] : buttonStyles["primary"]
          )}
          {...inspectorMode?.getProps("text")}
        >
          {props.entry.fields.link.fields.text}
        </div>
      )}
      {props.entry.fields.countdown && (
        <Countdown
          entry={props.entry}
          date={props.entry.fields.countdown}
          customStyles={{
            containerCountdown: styles.containerCountdown,
            wrapperCountdown: styles.wrapperCountdown,
            labelCountdown: styles.labelCountdown,
            numberCountdown: styles.numberCountdown,
          }}
        />
      )}
    </>
  );

  return (
    <div
      className={classNames(
        styles.container,
        serverSideProps?.page?.contentBelowHeader ? styles.contentBelowHeader : undefined,
        aspectRatioMobile !== "unset" ? styles.autoHeightMobile : undefined,
        aspectRatioDesktop !== "unset" ? styles.autoHeightDesktop : undefined
      )}
    >
      <div
        style={styleAspectRatio}
        className={classNames(
          styles.wrapperSlide,
          aspectRatioMobile !== "unset" ? styles.autoHeightMobile : undefined,
          aspectRatioDesktop !== "unset" ? styles.autoHeightDesktop : undefined
        )}
      >
        {props.entry.fields.link && isActiveEntry(props.entry.fields.link) ? (
          <Link
            href={textUtils.sanitizeContentfulUrl(props.entry.fields.link, router)}
            target={props.entry.fields.link.fields.openOnANewTab ? "_blank" : undefined}
          >
            <BreakpointAwareAsset
              entry={props.entry.fields.media}
              className={classNames(styles.image, props.entry.fields.moveMediaDown ? styles.moveMediaDown : undefined)}
              fill
              videoConfig={{ muted: true, loop: true, autoPlay: true, controls: false, playsInline: true }}
              noLazy={props.isHeroSlider ? props.noLazy : true}
            />
          </Link>
        ) : (
          <BreakpointAwareAsset
            entry={props.entry.fields.media}
            className={classNames(styles.image, props.entry.fields.moveMediaDown ? styles.moveMediaDown : undefined)}
            fill
            videoConfig={{ muted: true, loop: true, autoPlay: true, controls: false, playsInline: true }}
            noLazy={props.isHeroSlider ? props.noLazy : true}
          />
        )}
        <Container className={styles.h100}>
          <Grid className={styles.h100}>
            {/* panel could be moved into a dedicated component */}
            {/* dynamic wrapper panel */}
            {props.entry.fields.link && isActiveEntry(props.entry.fields.link) ? (
              <Link href={textUtils.sanitizeContentfulUrl(props.entry.fields.link, router)} className={panelClassName}>
                {panelContent}
              </Link>
            ) : (
              <div className={panelClassName}>{panelContent}</div>
            )}
          </Grid>
        </Container>
      </div>
    </div>
  );
}
